import * as ActionTypes from "../ActionTypes";
import {
  RegisterUserService,
  LoginUserService,
  ForgotPasswordService,
  ResetPasswordService,
  TestUserService,
} from "../../services/AuthServices";
import { setAllUserInfo } from "../reducers/UserReducer";
import { setAllLocationInfo } from "../reducers/LocationReducer";
import { setAllCompanyInfo } from "../reducers/CompanyReducer";
import { setAllRegionInfo } from "../reducers/RegionReducer";
import { resetGlobalState } from "../reducers/GlobalReducer";
import { resetCompanyState } from "../reducers/CompanyReducer";
import { resetRegionState } from "../reducers/RegionReducer";
import { resetLocationState } from "../reducers/LocationReducer";
import { resetUserState } from "../reducers/UserReducer";
import { persistor, store } from "../../redux/Store";
import { GetLocation } from "../../apiCalls/LocationCalls";
import { GetCompany } from "../../apiCalls/CompanyCalls";
import { GetRegion } from "../../apiCalls/RegionCalls";

export const resetForgotPasswordState = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESET_FORGOT_PASSWORD_STATE });
  };
};
export const resetResetPasswordState = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESET_RESET_PASSWORD_STATE });
  };
};

export const RegisterAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    RegisterUserService(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: ActionTypes.SIGNUP_SUCCESS, res });
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.SIGNUP_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const LoginAction = (credentials, history) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });

    // clear the states
    dispatch(resetGlobalState());
    dispatch(resetCompanyState());
    dispatch(resetRegionState());
    dispatch(resetLocationState());
    dispatch(resetUserState());

    dispatch({ type: ActionTypes.LOADING });

    try {
      const res = await LoginUserService(credentials);

      if (res.hasOwnProperty("success") && res.success === true) {
        const { tokens, user } = res;

        localStorage.setItem("user-token", tokens);
        localStorage.setItem("accessToken", tokens.AccessToken);

        dispatch({ type: ActionTypes.LOGIN_SUCCESS });
        dispatch(setAllUserInfo(user));

        const [locationInfo, companyInfo, regionInfo] = await Promise.all([
          GetLocation(user.location_id),
          GetCompany(user.company_id),
          GetRegion(user.region_id),
        ]);
        dispatch(setAllLocationInfo(locationInfo.data));
        dispatch(setAllCompanyInfo(companyInfo.data));
        dispatch(setAllRegionInfo(regionInfo.data));

        setTimeout(() => {
          history("/");
        }, 1500);
      } else if (res.hasOwnProperty("success") && res.success === false) {
        dispatch({ type: ActionTypes.LOGIN_ERROR, res });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.CODE_ERROR, error });
    }
  };
};
export const LogoutAction = (history) => {
  return (dispatch) => {
    // Redirect to login screen
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE }); // Reset authentication state

    // Dispatch logout action
    store.dispatch({ type: "LOGOUT" });
    // clear the states
    dispatch(resetGlobalState());
    dispatch(resetCompanyState());
    dispatch(resetRegionState());
    dispatch(resetLocationState());
    dispatch(resetUserState());

    // Purge the persisted state
    persistor.purge();

    // Clear user token from localStorage
    localStorage.removeItem("user-token");
  };
};

export const ClearAuthStateAction = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
  };
};

export const ForgotPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESET_FORGOT_PASSWORD_STATE });
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ForgotPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, res });
          //setTimeout(() => { history('/'); },1500);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const ResetPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });
    dispatch({ type: ActionTypes.RESET_RESET_PASSWORD_STATE });

    ResetPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_SUCCESS, res });
          setTimeout(() => {
            history("/");
          }, 1500);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const TestUserAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    TestUserService(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};
