import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "../../redux/actions/AuthActions";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import AuthCard from "../../components/auth/AuthCard";
import { useLocation } from "react-router-dom"; // Import useLocation
import { useEffect } from "react";
import { resetResetPasswordState } from "../../redux/actions/AuthActions";

function ResetPassword() {
  useEffect(() => {
    // Dispatch the reset action here
    dispatch(resetResetPasswordState());
  }, [dispatch]);
  const authResponseSuccess = useSelector(
    (state) => state.userAuth.authResponse.success
  );
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm(); // for form control
  const dispatch = useDispatch();
  const authResponse = useSelector((state) => state.userAuth.authResponse);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code"); // Extract token
  const user = searchParams.get("user"); // Extract email

  // handle submit
  const onSubmit = (data) => {
    // check and make sure passwords match
    if (data.password === data.password_confirm && code !== "") {
      var fields = {
        email: user.replace(/'/g, ""), // Remove single quotes if present
        confirmation_code: code.replace(/'/g, ""), // Remove single quotes if present
        new_password: data.password,
      };

      dispatch(ResetPasswordAction(fields));
    } //[end] check if passwords match
  }; // [end] on submit

  return (
    <>
      <div className="admin-auth-page">
        <AuthCard>
          {authResponseSuccess ? (
            <div className="row auth-response-text">
              <p className="page-title">Password Successfully Changed.</p>
              <div className="row">
                <Link to="/login">Return to login</Link>
              </div>
            </div>
          ) : (
            <>
              <p className="page-title">Admin Password Reset</p>
              <form>
                <div className="row">
                  <div
                    className={`input-wrapper ${
                      errors["password"] ? "has-error" : ""
                    }`}
                  >
                    <label htmlFor="password">New Password *</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      {...register("password", {
                        required: true,
                        minLength: 3,
                        maxLength: 35,
                      })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`input-wrapper ${
                      errors["password_confirm"] ? "has-error" : ""
                    }`}
                  >
                    <label htmlFor="password_confirm">
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      name="password_confirm"
                      placeholder="Password"
                      {...register("password_confirm", {
                        required: true,
                        minLength: 3,
                        maxLength: 35,
                      })}
                    />
                  </div>
                </div>

                {/* show submission errors if they exist */}
                {!authResponse.success ? (
                  <div className="row">
                    {" "}
                    <p>{authResponse.message}</p>{" "}
                  </div>
                ) : null}
                {watch("password_confirm") !== watch("password") &&
                getValues("password_confirm") ? (
                  <div className="row">
                    {" "}
                    <p>passwords do not match</p>{" "}
                  </div>
                ) : null}

                <div className="row">
                  <button
                    type="submit"
                    className="button-blue"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save New Password
                  </button>
                </div>
              </form>
            </>
          )}
        </AuthCard>
      </div>
    </>
  ); //[end] return function
} // [end] ResetPassword()
export default ResetPassword;
