import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CardHeading from "../CardHeading";
import "react-datetime/css/react-datetime.css"; // for datepicker
import { GetUser } from "../../apiCalls/UserCalls";
import { GetAllLocations } from "../../apiCalls/LocationCalls";
import { GetAllRegions } from "../../apiCalls/RegionCalls";
import { useSelector, useDispatch } from "react-redux";
import { GetAllCompanies } from "../../apiCalls/CompanyCalls";
import { AddUser, UpdateUser } from "../../apiCalls/UserCalls";
import { useAlert } from "../../context/AlertContext";
import { setAllUserInfo } from "../../redux/reducers/UserReducer";

function CompetentTrainerInfoForm(props) {
  const action = props.action.trim() || "update";
  // set card and button title based on new or update
  const cardTitle =
    action === "update"
      ? "Edit Competent Trainer"
      : "Add New Competent Trainer";
  const buttonTitle = action === "update" ? "Edit Trainer" : "Add Trainer";
  // the current user is the person who is logged in
  const current_user = useSelector((state) => state.user);
  const current_user_role = current_user.role;
  // the passedInId is the id of the user we are editing
  const passedInId = props.id != null ? props.id : 0;
  // register alerts
  const { addAlert } = useAlert();
  const dispatch = useDispatch();

  // set default values for form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company_selection:
        action === "add" ? current_user.company_id.toString() : "",
      region_selection:
        action === "add" ? current_user.region_id.toString() : "",
      location_selection:
        action === "add" ? current_user.location_id.toString() : "",
      user_permissions: "",
    },
  });
  const password = watch("password"); // Watch the password field

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [allRegions, setAllRegions] = useState([]); // Store all regions
  const [allLocations, setAllLocations] = useState([]); // Store all locations
  const [companyChoices, setCompanyChoices] = useState([]); // for storing companies
  const [regionChoices, setRegionChoices] = useState([]); // for storing regions
  const [locationChoices, setLocationChoices] = useState([]); // for storing locations

  // get user info
  useEffect(() => {
    async function fetchUser() {
      const result = await GetUser(passedInId);
      if (result.success) {
        // Set form values
        let data = result.data;
        setValue("first_name", data.first_name);
        setValue("last_name", data.last_name);
        setValue("email", data.email);
        setValue("phone", data.phone);
        setValue("user_permissions", data.role.toString());
        setValue("company_selection", data.company_id.toString());
        setValue("region_selection", data.region_id.toString());
        setValue("location_selection", data.location_id.toString());
        setSelectedCompany(data.company_id.toString());
        setSelectedRegion(data.region_id.toString());
        setSelectedLocation(data.location_id.toString());

        // Filter regions and locations based on the user's company and region
        const filteredRegions = allRegions.filter(
          (region) => region.company_id === data.company_id
        );
        setRegionChoices(filteredRegions);

        const filteredLocations = allLocations.filter(
          (location) => location.region_id === data.region_id
        );
        setLocationChoices(filteredLocations);
      } else {
        console.log(result.message);
      }
    }
    if (action === "update" && passedInId !== 0) {
      fetchUser();
    }
  }, [passedInId, action, setValue, allRegions, allLocations]);

  // get all companies, regions, and locations
  useEffect(() => {
    const fetchInitialData = async () => {
      const companiesResult = await GetAllCompanies();
      if (companiesResult.success) {
        setCompanyChoices(companiesResult.data);
      }

      const regionsResult = await GetAllRegions();
      if (regionsResult.success) {
        setAllRegions(regionsResult.data);
        setRegionChoices(regionsResult.data);
      }

      const locationsResult = await GetAllLocations();
      if (locationsResult.success) {
        setAllLocations(locationsResult.data);
        setLocationChoices(locationsResult.data);
      }
    };

    fetchInitialData();

    if (action === "add") {
      setSelectedCompany(current_user.company_id.toString());
      setSelectedRegion(current_user.region_id.toString());
      setSelectedLocation(current_user.location_id.toString());
    }
  }, [action, current_user]);

  // Handler for company selection change
  const handleCompanyChange = (
    selectedCompanyId,
    allRegionsList = allRegions
  ) => {
    setSelectedCompany(selectedCompanyId);

    // Filter regions based on selected company
    const filteredRegions = allRegionsList.filter(
      (region) => region.company_id.toString() === selectedCompanyId
    );
    setRegionChoices(filteredRegions);

    // Reset selected region and location when company changes
    setSelectedRegion("");
    setSelectedLocation("");
    setLocationChoices([]);
  };

  // Handler for region selection change
  const handleRegionChange = (
    selectedRegionId,
    allLocationsList = allLocations
  ) => {
    setSelectedRegion(selectedRegionId);

    // Filter locations based on selected region
    const filteredLocations = allLocationsList.filter(
      (location) => location.region_id.toString() === selectedRegionId
    );
    setLocationChoices(filteredLocations);

    // Reset selected location when region changes
    setSelectedLocation("");
  };

  const handleLocationChange = (selectedLocationId) => {
    setSelectedLocation(selectedLocationId);
  };
  const onSubmit = (formData) => {
    // Sanitize the phone number to contain only digits
    if (formData.phone) {
      formData.phone = formData.phone.replace(/\D/g, ""); // Remove non-digit characters
    }
    // Construct the submission object with the correct key names
    const submissionDataUser_Add = {
      // this is redundant but necessary for the API
      Username: formData.email,
      Password: formData.password,
      email: formData.email,
      password: formData.password,
      customAttributes: [
        { name: "first_name", value: formData.first_name },
        { name: "last_name", value: formData.last_name },
        { name: "phone", value: formData.phone },
        { name: "company_id", value: formData.company_selection },
        { name: "region_id", value: formData.region_selection },
        { name: "location_id", value: formData.location_selection },
        { name: "role", value: formData.user_permissions },
      ],
    };

    const submissionDataUser_Update = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
      company_id: formData.company_selection,
      region_id: formData.region_selection,
      location_id: formData.location_selection,
      role: formData.user_permissions,
      email: formData.email,
    };

    if (action === "update") {
      // update the user
      UpdateUser(passedInId, submissionDataUser_Update).then(async (result) => {
        if (result.success) {
          addAlert("User Updated", "success", false);
          // update grid data if we came from a grid
          if (typeof props.onSuccessfulOperation === "function") {
            props.onSuccessfulOperation();
          }
          if (props.onClose) {
            props.onClose(); // close the modal
          }
          // update state if we are updating the current location
          const updateInfo = async () => {
            if (passedInId.toString() === current_user.id.toString()) {
              const entityInfo = await GetUser(passedInId);
              dispatch(setAllUserInfo(entityInfo.data));
            }
          };

          // Call the async function
          await updateInfo();
        } else {
          addAlert("An error occurred!", "error", true);
          console.log(result.message);
        }
      });
    } else {
      // add the user
      AddUser(submissionDataUser_Add)
        .then((response) => {
          if (response.success) {
            addAlert("User Created", "success", false);
            // update grid data if we came from a grid
            if (typeof props.onSuccessfulOperation === "function") {
              props.onSuccessfulOperation();
            }
            if (props.onClose) {
              props.onClose(); // close the modal
            }
          }
        })
        .catch((error) => {
          console.error(error);
          addAlert("An error occurred!", "error", true);
        });
    }
  };

  return (
    <>
      <CardHeading title={cardTitle} />

      <form>
        <div className="row">
          <div
            className={`half-width ${errors["first_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              placeholder={`First Name`}
              {...register("first_name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
          <div
            className={`half-width ${errors["last_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              placeholder={`Last Name`}
              {...register("last_name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className={`half-width`}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder={`Email`}
              {...register("email", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
            />
          </div>

          <div className={`half-width`}>
            <label htmlFor="phone">Phone Number</label>
            <input
              type="phone"
              name="phone"
              placeholder={`Phone`}
              {...register("phone", {
                required: true,
                minLength: 10,
                maxLength: 18,
              })}
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`half-width ${
              errors["company_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="company_selection">
              Company {current_user_role <= 4 ? "(locked)" : ""}
            </label>
            <select
              name="company_selection"
              {...register("company_selection", { required: true })}
              onChange={(e) => handleCompanyChange(e.target.value)}
              className={current_user_role <= 4 ? "select-readonly" : ""} // Disable if user is not at least a Company Admin
              value={selectedCompany}
            >
              <option value="">Select...</option>
              {companyChoices.map((company) => (
                <option key={company.id} value={company.id}>
                  {" "}
                  {company.name}{" "}
                </option>
              ))}
            </select>
          </div>

          <div
            className={`half-width ${
              errors["region_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="region_selection">
              Region {current_user_role <= 3 ? "(locked)" : ""}
            </label>
            <select
              name="region_selection"
              {...register("region_selection", { required: true })}
              onChange={(e) => handleRegionChange(e.target.value)}
              className={current_user_role <= 3 ? "select-readonly" : ""} // Disable if user is not at least a Region Admin
              value={selectedRegion}
            >
              <option value="">Select...</option>
              {regionChoices.map((region) => (
                <option key={region.id} value={region.id}>
                  {" "}
                  {region.name}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div
            className={`half-width ${
              errors["location_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="location_selection">Location</label>
            <select
              name="location_selection"
              {...register("location_selection", { required: true })}
              disabled={!selectedRegion || current_user_role <= 2} // Disable if no region is selected or user is not a Region Manager
              value={selectedLocation}
              onChange={(e) => handleLocationChange(e.target.value)}
            >
              <option value="">Select...</option>
              {locationChoices.map((location) => (
                <option key={location.id} value={location.id}>
                  {" "}
                  {location.name}{" "}
                </option>
              ))}
            </select>
          </div>

          <div
            className={`half-width ${
              errors["region_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="user_permissions">Permissions</label>
            <select
              name="user_permissions"
              {...register("user_permissions", { required: true })}
            >
              <option value="">Select...</option>
              {current_user_role >= 1 && <option value="1">Trainer</option>}
              {current_user_role >= 2 && (
                <option value="2">Location Manager</option>
              )}
              {current_user_role >= 3 && (
                <option value="3">Region Manager</option>
              )}
              {current_user_role >= 4 && (
                <option value="4">Company Manager</option>
              )}
              {current_user_role >= 5 && (
                <option value="5">Q4 Super Admin</option>
              )}
            </select>
          </div>
        </div>

        {/* Conditionally render password field */}
        {action === "add" && (
          <div className="row">
            <div
              className={`half-width ${errors["password"] ? "has-error" : ""}`}
            >
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
                    message:
                      "Password must contain an uppercase, lowercase, number, and special character",
                  },
                })}
              />
              Password must be at least 6 characters and contain an uppercase,
              lowercase, number, and special character
              {/* {errors.password && <p>Password is required</p>} */}
              {errors.password && <p>{errors.password.message}</p>}
            </div>
            <div
              className={`half-width ${
                errors["retypePassword"] ? "has-error" : ""
              }`}
            >
              <label htmlFor="retypePassword">Retype Password</label>
              <input
                type="password"
                name="retypePassword"
                placeholder="Retype Password"
                {...register("retypePassword", {
                  required: "Please retype your password",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
              {errors.retypePassword && <p>{errors.retypePassword.message}</p>}
            </div>
          </div>
        )}

        <div className="form-bottom-button-wrapper">
          <button
            type="submit"
            className="button button-blue"
            onClick={handleSubmit(onSubmit)}
          >
            {buttonTitle}
          </button>
        </div>
      </form>
    </>
  );
}

export default CompetentTrainerInfoForm;
